import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'rkb-toggle',
  standalone: true,
  imports: [MatButtonToggleModule],
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() labels: string[] = [];
  @Input() selectedValue: string;

  @Output() selectionChange = new EventEmitter<string>();

  onToggleChange(value: string) {
    this.selectionChange.emit(value);
  }

  ngOnInt() {}
}
