import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, Observable, startWith, combineLatest, BehaviorSubject } from 'rxjs';
import { ChipComponent } from '../chip/chip.component';
import { FormFieldComponent } from '../form-field/form-field.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { DropdownOption } from '../../models/dropdown-option.model';

@Component({
  selector: 'rkb-input-dropdown-multiselect',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormFieldComponent,
    SvgIconComponent,
    ChipComponent,
  ],
  templateUrl: './input-dropdown-multiselect.component.html',
  styleUrls: ['./input-dropdown-multiselect.component.scss'],
})
export class InputDropdownMultiselectComponent {
  @Input()
  options: DropdownOption[] = [];

  @Output()
  selectedItemsChange = new EventEmitter<DropdownOption[]>();

  searchControl = new FormControl('');
  filteredOptions: Observable<DropdownOption[]>;
  selectedItems$ = new BehaviorSubject<DropdownOption[]>([]);

  ngOnInit() {
    this.filteredOptions = combineLatest([
      this.searchControl.valueChanges.pipe(
        startWith(''),
        map(value =>
          typeof value === 'string' ? value : (value as DropdownOption)?.label || '',
        ),
      ),
      this.selectedItems$,
    ]).pipe(map(([value, selectedItems]) => this._filter(value, selectedItems)));
  }

  private _filter(
    searchValue: string,
    selectedItems: DropdownOption[],
  ): DropdownOption[] {
    const filterValue = searchValue.toLowerCase();
    return this.options
      .filter(option => option.label.toLowerCase().includes(filterValue))
      .filter(option => !selectedItems.some(selected => selected.value === option.value));
  }

  displayFn(option: DropdownOption): string {
    return option && option.label ? option.label : '';
  }

  addOption(option: DropdownOption) {
    if (option && !this.selectedItems$.value.some(item => item.value === option.value)) {
      this.selectedItems$.next([...this.selectedItems$.value, option]);
      this.selectedItemsChange.emit(this.selectedItems$.value);
    }
    this.searchControl.setValue('');
  }

  removeOption(option: DropdownOption) {
    const updatedItems = this.selectedItems$.value.filter(
      item => item.value !== option.value,
    );
    this.selectedItems$.next(updatedItems);
    this.selectedItemsChange.emit(updatedItems);
  }
}
