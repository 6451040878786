<mat-button-toggle-group
  [value]="selectedValue"
  (change)="onToggleChange($event.value)"
  aria-label="Toggle Group"
>
  @for (label of labels; track label) {
    <mat-button-toggle [value]="label">
      {{ label }}
    </mat-button-toggle>
  }
</mat-button-toggle-group>
