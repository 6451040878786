<div class="w-full flex items-center justify-between">
  @if(label) {
    <label>{{ label }}</label>
  }
  <button
  type="button"
  class="switch"
  [class.checked]="isChecked"
  [class.disabled]="isDisabled"
  (click)="onToggle()"
>
  <div
    class="switch-button"
    [class.checked]="isChecked"
    [class.disabled]="isDisabled"
  ></div>
</button>
</div>
